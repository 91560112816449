$gev-app-primary: #2b5279;
$gev-dark-primary: #2b5279;
$gev-light-primary: #C5CAE9;
$gev-primary-text: #212121;
$gev-accent-text: #2b5279;
$gev-secondary-text:#757575;
$gev-divider: #BDBDBD;
$gev-icons: #FFFFFF;
$gev-app-warn: indianred;

.component-container {
  margin-top: 1rem;
  display: block;
  padding: 1rem;
  width: auto;

  .p-3 {
    padding: 1.3rem;
  }

  .p-2 {
    padding: 1.3rem;
  }

  .p-1 {
    padding: 1.1rem;
  }

}
