@import "~@angular/material/prebuilt-themes/indigo-pink.css";
// @import "assets/styles/greenconsulting_theme.scss";

body {
  margin: 0;
  font-family: Raleway;
  position: relative;

}

.mat-drawer-backdrop.mat-drawer-shown {
  display: none;
}

.mat-drawer-backdrop.mat-drawer-shown {
  visibility: hidden !important;
}
